import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import axios from 'axios';
import Atropos from 'atropos/react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
// import "../../";

export default function AgentData() {

    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }
    const location = useLocation();
    const path = location.pathname.split("/")[3];

  const [Agent, setAgent] = useState()
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

    const admin = (s,e) => {
        const access_token = localStorage.getItem("token")
        const headers = {
          Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`agent/allData/${path}?startingfrom=`+s+ "&endto="+e, { headers })
          .then((res) => {
            setAgent(res.data)
          })
      }

      function filterData() {
        admin(new Date(startDate), new Date(endDate));
      }
    
      function daterange(s, e) {
        if (s > e) {
          alert("Starting date is greater than ending date");
        } else {
          setStartDate(s);
          setEndDate(e);
        }
      console.log(s,e)
        // Change start date format
        var startDate = new Date(s);
        var startDay = startDate.getDate();
        var startMonth = startDate.getMonth() + 1; // Months are zero-based
        var startYear = startDate.getFullYear();
        var startHours = startDate.getHours();
        var startMinutes = startDate.getMinutes();
        var startSeconds = startDate.getSeconds();
      
        // Change end date format
        var endDate = new Date(e);
        var endDay = endDate.getDate();
        var endMonth = endDate.getMonth() + 1; // Months are zero-based
        var endYear = endDate.getFullYear();
        var endHours = endDate.getHours();
        var endMinutes = endDate.getMinutes();
        var endSeconds = endDate.getSeconds();
      
      
      }


      useEffect(() => {
        admin(new Date(new Date().setHours(0,0,0,0)), new Date());
      },[])

    return (
        <>
            {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
            <div className="row ">
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TODAY DEPOSIT</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.todayDeposit} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TOTAL DEPOSIT</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.totalDeposit} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TODAY WITHDRAW</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.todayWithdraw} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TOTAL WITHDRAW</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.totalWithdraw} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TODAY BONUS</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.todayBouns} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TOTAL BONUS</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.totalBouns} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TODAY PENALTY</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.todayPenalty} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            <Atropos rotateXMax={15} shadowScale={0.7} shadowOffset={50} rotateYMax={15} stretchX={50} className="col-xl-3 col-sm-6 grid-margin stretch-card my-atropos" >
            <div className="card">
              <div className="card-body text-light border-primary"  >
                <h4 className="font-weight-normal text-primary">TOTAL PENALTY</h4>
                <div className="d-flex align-items-center align-self-start">
                  <h3 className=" display-3 text-primary mb-0"><CountUp start={0} delay={0.1} duration={0.2} end={Agent?.totalPenalty} /></h3>
                </div>
              </div>
            </div>
          </Atropos>
            </div>
        </>
    )
}
